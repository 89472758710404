import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import React, { Fragment, useEffect, useState } from "react";
import Image from "next/image";
import { RecruitmentJob } from "../../repositories/models/RecruitmentJob";
import axios from "axios";
import router from "next/router";
import _ from "lodash";
export const IntroduceJob = () => {
  const [jobList, setJobList] = useState<RecruitmentJob[][]>();
  const [loading, setLoading] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    let chunk = 10;
    const desktop = window.matchMedia("(min-width: 430.5px)");
    if (!desktop.matches) {
      chunk = 5;
    }
    setIsDesktop(desktop.matches);
    fetchingJob(chunk);
  }, []);

  function splitArray(array: any, chunkSize: any) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  const toDetailJob = (id: any) => {
    router.push("/job/" + id);
  };

  function JobListLoading() {
    const jobLoads = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    const listItems = jobLoads.map((job, index) => (
      <div
        key={index}
        className="mb:w-[500px] w-[330px] mb:h-[77.5px] h-[70px] rounded-xl border border-gray-200 border-solid mb-6 mx-auto flex relative bg-white animate-pulse shadow"
      >
        <div className="bg-gray-200 w-[51.85px] h-[53.86px] my-auto ml-4"></div>
        <ul className="ml-4">
          <li className="flex mt-1">
            <div className="bg-gray-200 mb:w-[270px] mb:h-[24px] w-[230px] h-[20px]"></div>
          </li>
          <li className="flex mt-1 mb-1 items-center">
            <div className="mb:w-[270px] mb:h-[16px] w-[230px] h-[14px] bg-gray-200"></div>
          </li>
          <li className="flex items-center">
            <div className="bg-gray-200 p-0.5 rounded mr-2 mb:w-[40px] w-[34px] mb:h-[20px] h-[17px]"></div>
            <div className="bg-gray-200 p-0.5 rounded mr-2 mb:w-[60px] w-[57px] mb:h-[20px] h-[17px]"></div>
          </li>
        </ul>
        <div className="bg-gray-200 w-[54.81px] h-[25.33px] mt-2 ml-20 mb:block hidden"></div>
        <div className="bg-gray-200 w-[43px] h-[19px] mb:hidden absolute right-4 bottom-1"></div>
      </div>
    ));
    return (
      <>
        <div className="bg-gray-100 rounded flex px-8 py-10 justify-between flex-wrap min-h-[650px]">{listItems}</div>
      </>
    );
  }

  const fetchingJob = async (chunk: any) => {
    setLoading(true);
    let url_fetch = `${process.env.NEXT_PUBLIC_API_URL}/recruitment/all-office-jobs?`;
    try {
      const res = await axios.get(url_fetch);
      let customerJobs = splitArray(res.data.data, chunk);
      setJobList(customerJobs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching jobs:", error);
    }
  };

  const Area = (props: any) => {
    let { areas } = props;
    if (areas) {
      var array = areas?.split(",");
    } else {
      array = [];
    }
    const listArea = array.map((job: any, index: any) => (
      <div key={index} className="bg-gray-300 p-0.5 rounded mr-2">
        {job?.trim()}
      </div>
    ));
    return <>{listArea}</>;
  };

  const listItems = jobList?.map((jobs, index) => (
    <SwiperSlide key={index}>
      <div className="bg-gray-100 rounded flex px-8 py-10 xl:justify-between justify-center flex-wrap min-h-[650px]">
        {jobs.map((job, jobIndex) => (
          <div key={jobIndex}>
            <div
              onClick={() => {
                toDetailJob(job.id);
              }}
              className="mb:w-[500px] w-[330px] mb:h-[90px] mb:pb-0 p-2 h-auto rounded-xl border border-gray-200 border-solid mb-6 mx-auto flex relative cursor-pointer"
            >
              <Image
                src={`${job.avatar ? job.avatar : "/images/default.jpg"}`}
                alt="default.jpg"
                className="my-auto ml-4 h-[53.86px] w-[51.85px] object-cover"
                width={51.85}
                height={53.86}
              />
              <ul className="ml-4">
                <li className="flex mt-1">
                  <span className="font-gotham-regular text-blue-violet md:text-base text-sm">
                    {job.name.length > 30 ? job.name.slice(0, 30) + "...." : job.name}
                  </span>
                </li>
                <li className="font-gotham-book flex text-black mb:text-xs text-[10px] mb-1 items-center">
                  <Image src="/images/salary_job.svg" alt="salary_job" className="mr-1" width={9} height={9} />
                  <span className="mb:block hidden">{job.excerpt.length > 50 ? job.excerpt.slice(0, 50) + "...." : job.excerpt}</span>
                  <span className="mb:hidden block">{job.excerpt.length > 30 ? job.excerpt.slice(0, 30) + "...." : job.excerpt}</span>
                </li>
                <li className="font-gotham-book flex text-black mb:text-xs text-[9px] items-center mb-2">
                  <Area areas={job.area} />
                </li>
              </ul>
              {job.is_special == 1 && (
                <>
                  <Image
                    src="/images/hot_job.svg"
                    alt="hot_job"
                    className="mt-2 absolute right-2 h-fit mb:block hidden"
                    width={54.81}
                    height={25.33}
                  />
                  <Image src="/images/hot_job.svg" alt="hot_job" className="mt-2 h-fit mb:hidden absolute right-4 bottom-2" width={43} height={19} />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </SwiperSlide>
  ));

  return (
    <>
      <div id="introJobDesktop" className="mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] pb-10 font-gotham-book mt-12">
        <div className="bg-blue-violet flex justify-center items-center h-[86px] rounded-t-2xl">
          <h2 className="font-gotham-book text-2xl text-white">THẾ GIỚI VIỆC LÀM EDUTALK</h2>
        </div>
        <Fragment>
          <Swiper
            spaceBetween={13}
            centeredSlides={true}
            slidesPerView={1}
            modules={[Pagination, Autoplay]}
            className=" cursor-pointer"
            pagination={{ clickable: true }}
            loop={true}
            speed={400}
            roundLengths={true}
            loopAdditionalSlides={30}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
          >
            {!loading ? listItems : <JobListLoading />}
          </Swiper>
        </Fragment>
      </div>
    </>
  );
};
